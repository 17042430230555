import Vue from 'vue'
import VueRouter from 'vue-router'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        name: 'home',
        component: ()=>import("@/layout/LayoutMain.vue"),
        redirect: 'index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/dashboard/index.vue')
            },
            {
                path: 'project',
                component: () => import('@/views/project/index.vue')
            },
            {
                path: 'exclusiveproject',
                component: () => import('@/views/exclusiveproject/index.vue')
            },
            {
                path: 'merchant',
                component: () => import('@/views/merchant/index.vue')
            },
            {
                path: 'user',
                component: () => import('@/views/user/index.vue')
            },
            {
                path: 'version',
                component: () => import('@/views/version/index.vue')
            },
            {
                path: 'token',
                component: () => import('@/views/token/index.vue')
            },
            {
                path: 'param',
                component: () => import('@/views/param/index.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/index.vue')
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token')
    if (to.name !== 'login' && !token) {
        router.push('/login')
    } else {
        next()
    }
})

export default router
